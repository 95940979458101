import { Icon, SvgComponent } from 'Atoms/Icon';
import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export interface Props {
  className?: string;
  name: string;
  icon: SvgComponent;
  active?: boolean;
  onClick?: () => void;
}

const TabBase: FC<Props> = ({ className, name, icon, onClick }) => (
  <button className={className} onClick={onClick}>
    <StyledIcon svgComponent={icon} />
    {name}
  </button>
);

export const Tab = styled(TabBase)`
  display: flex;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.colors.tab.default};
  font: ${props => props.theme.fonts.bigTextSemiBold};
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    font: ${props => props.theme.fonts.mediumTextSemiBold};
  }
  padding: 18px 0;
  background: none;

  transition-property: color, background, border;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  white-space: nowrap;

  ${StyledIcon} {
    transition: all 0.2s linear;
    fill: ${props => props.theme.colors.tab.default};
  }

  &:hover {
    color: ${props => props.theme.colors.tab.hover};
    ${StyledIcon} {
      fill: ${props => props.theme.colors.tab.hover};
    }
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.tab.focus};
  }

  ${props =>
    props.active &&
    css`
      font-weight: 600;
      color: ${props => props.theme.colors.tab.active};
      ${StyledIcon} {
        fill: ${props => props.theme.colors.tab.active};
      }
    `}
`;
