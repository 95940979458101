import { Icon } from 'Atoms/Icon';
import { Tab } from 'Molecules/Tab';
import { FC, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { EBookSection } from 'types/eBooks';

export const TabsContainer = styled.nav`
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.tab.border};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const Slider = styled.div`
  background-color: transparent;
  position: absolute;
  bottom: 0;
  border-bottom: 5px solid ${props => props.theme.colors.tab.borderActive};
  transition: all 0.6s;
  width: 182px;
`;

const TabStyled = styled(Tab)`
  width: 200px;
  ${Icon} {
    min-width: 25px;
  }

  &:nth-child(1) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 200px;
          left: 0;
        }
      `}
  }

  &:nth-child(2) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 200px;
          left: 200px;
        }
      `}
  }

  &:nth-child(3) {
    ${props =>
      props.active &&
      css`
        & ~ ${Slider} {
          width: 200px;
          left: 400px;
        }
      `}
  }
`;

interface Props {
  className?: string;
  sections: EBookSection[];
}

export const Tabs: FC<Props> = ({ className, sections }): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <TabsContainer className={className}>
      {sections.map((section, i) => (
        <TabStyled
          name={section.name}
          icon={section.icon}
          key={i}
          onClick={() => navigate(section.slug)}
          active={pathname.includes(section.slug)}
        />
      ))}
      <Slider />
    </TabsContainer>
  );
};
