import { AxiosError } from 'axios.cached';
import { NotFound } from 'pages/404';
import { ErrorPage } from 'pages/Error';
import { Component, ReactNode } from 'react';
import { AccountStateContext } from 'store/accountStore/provider';
import { CustomError, ErrorCodes, Errors } from 'types/errorTypes';

interface ErrorBoundaryProps {
  children: ReactNode;
  pathname?: string;
}

interface ErrorBoundaryState {
  errorData?: Errors;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  getIsNotFound = (error?: Errors): boolean =>
    (error &&
      (error as AxiosError).isAxiosError &&
      (error as AxiosError).response?.status === 404) ||
    (error as CustomError).code === ErrorCodes.NotFound;

  static getDerivedStateFromError(error?: Errors): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.

    return { errorData: error || new Error() };
  }

  componentDidUpdate(previousProps: ErrorBoundaryProps): void {
    if (previousProps.pathname !== this.props.pathname) {
      this.setState({ errorData: undefined });
    }
  }

  render(): ReactNode {
    const { errorData } = this.state;

    if (!errorData) {
      return this.props.children;
    }

    if (this.getIsNotFound(errorData)) {
      return <NotFound />;
    } else {
      return <ErrorPage error={errorData} />;
    }
  }
}

ErrorBoundary.contextType = AccountStateContext;
