import { EBook } from 'Molecules/EBook';
import { SearchSelect } from 'Molecules/select/SearchSelect';
import { FC, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { EBook as EBookType } from 'types/eBooks';
import { SelectOption } from 'types/select';

const Container = styled.div`
  display: flex;
  padding: 20px 0;

  flex-wrap: wrap;
  justify-content: center;
`;

const TopContainer = styled.div`
  position: relative;
  width: 100%;

  margin: 0 0 20px 0;

  display: flex;
  flex-wrap: wrap;
`;

const SearchSelectStyled = styled(SearchSelect)`
  margin: 10px 0;
  width: 400px;

  height: 48px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const EditionSelectStyled = styled(SearchSelect)`
  margin: 10px 0 0 auto;
  width: 400px;

  height: 48px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

interface Props {
  eBooks: EBookType[];
  options: SelectOption[];
  editionOptions: SelectOption[];
  currentEdition: string | undefined;
  setCurrentEdition: (value: string | null) => void;
}

export const EBookContainer: FC<Props> = ({
  eBooks,
  options,
  editionOptions,
  currentEdition,
  setCurrentEdition,
}) => {
  const [searchOption, setSearchOption] = useState<SelectOption | null>(null);

  useLayoutEffect(() => {
    if (searchOption) {
      const element = document.getElementById(searchOption.value);
      const rect = element?.getBoundingClientRect();

      const yOffsetToElement = window.pageYOffset + (rect?.top || 0);

      window.scrollTo(0, yOffsetToElement);
    }
  }, [searchOption]);

  const onClick = (eBook: EBookType): void => {
    const params = new URLSearchParams({
      title: eBook.title,
    });

    window.open(`/pdf?${params.toString()}&year=${currentEdition}`);
  };

  return (
    <Container>
      <TopContainer>
        <SearchSelectStyled
          classNamePrefix="search-select"
          placeholder="Quick search"
          options={options}
          showIcon
          value={searchOption}
          onChange={value => setSearchOption(value)}
          isClearable
          ariaLabel="Quick search"
        />
        <EditionSelectStyled
          classNamePrefix="search-select"
          placeholder="Select ebooks edition"
          options={editionOptions}
          value={editionOptions.find(o => o.value === currentEdition) || null}
          onChange={selected => setCurrentEdition(selected?.value || null)}
        />
      </TopContainer>
      {eBooks.map(eBook => (
        <EBook
          key={eBook.title}
          data={eBook}
          onClick={() => onClick(eBook)}
          isHighlighted={eBook.title === searchOption?.value}
        />
      ))}
    </Container>
  );
};
