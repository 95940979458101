import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'EBooks/List/LoadInitiated': {
      return { ...state, list: Loading };
    }
    case 'EBooks/List/Loaded': {
      return { ...state, list: action.payload };
    }
    case 'EBooks/List/LoadFailed': {
      return { ...state, list: action.payload };
    }
    case 'EBooks/Urls/LoadInitiated': {
      return { ...state, urls: { ...state.urls, [action.payload.key]: Loading } };
    }
    case 'EBooks/Urls/Loaded':
    case 'EBooks/Urls/LoadFailed': {
      return { ...state, urls: { ...state.urls, [action.payload.key]: action.payload.value } };
    }
    case 'EBooks/Editions/LoadInitiated': {
      return {
        ...state,
        availableEditions: Loading,
      };
    }
    case 'EBooks/Editions/LoadFailed':
    case 'EBooks/Editions/Loaded': {
      return {
        ...state,
        availableEditions: action.payload,
      };
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
