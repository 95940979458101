import { getInstitutionList } from 'apiServices/auth/auth';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { SignInInput } from 'Atoms/input/SignInInput';
import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Loader } from 'Atoms/Loader';
import { FormMessage } from 'Molecules/FormMessage';
import { AccountModal } from 'Molecules/modal/AccountModal';
import { ReCaptchaNoticeText } from 'Molecules/ReCaptchaNoticeText';
import { AsyncSelect } from 'Molecules/select/AsyncSelect';
import { FC, FormEvent, RefObject, useContext, useState } from 'react';
import { ErrorFields } from 'store/accountStore/hooks';
import styled, { ThemeContext } from 'styled-components/macro';
import { InstitutionSelectOption } from 'types/institution';

const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-right: 0;
  }
`;

const Label = styled.label`
  display: flex;
  font: ${props => props.theme.fonts.bigTextSemiBold};
  align-self: flex-start;
`;

const LabelPassword = styled.label`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bigTextSemiBold};
  align-self: flex-start;

  margin: 10px 0 0 0;
`;

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const IndividualForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px;
`;

const StyledInput = styled(SignInInput)`
  margin: 10px 0 0 0;
`;

const FormButton = styled(FilledButton)`
  width: 40%;
  max-width: 200px;
  padding: 10px 15px;
  margin-top: auto;
`;

const Links = styled.nav`
  margin-top: 40px;
  width: 100%;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 400px;
  margin-left: 15px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 100px 0 0 0;
  }
`;

const InstitutionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

const StyledSelect = styled(AsyncSelect<InstitutionSelectOption, false>())`
  margin-top: 10px;

  .${props => props.classNamePrefix}__control {
    &--is-focused,
    &--menu-is-open {
      .${props => props.classNamePrefix}__placeholder {
        display: block;
      }
    }
  }

  .${props => props.classNamePrefix}__dropdown-indicator {
    display: ${props => !props.showDropdown && 'none'};
  }
`;

const FormErrorMessage = styled(FormMessage)`
  margin-bottom: 10px;
`;

interface Props {
  className?: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  errorMessage: ErrorFields;
  onUsernameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  username: string;
  password: string;
  isFormLoading?: boolean;
  isPageLoading?: boolean;
  usernameInput?: RefObject<HTMLInputElement>;
  passwordInput?: RefObject<HTMLInputElement>;
}

export const SignInForm: FC<Props> = ({
  className,
  onSubmit,
  errorMessage,
  onUsernameChange,
  onPasswordChange,
  username,
  password,
  isFormLoading,
  isPageLoading,
  usernameInput,
  passwordInput,
}) => {
  const [institution, setInstitution] = useState<InstitutionSelectOption | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const theme = useContext(ThemeContext);

  const onInstitutionChange = (value: InstitutionSelectOption | null): void => {
    setInstitution(value);
  };

  const loadInstitutions = (
    value: string,
    callback: (values: InstitutionSelectOption[]) => void
  ): void => {
    getInstitutionList(value).then(list => {
      const selectOptions: InstitutionSelectOption[] = list.map(l => ({
        label: l.name,
        url: l.url,
        value: l.name,
        userGuide: l.userGuide,
        email: l.contactEmail,
      }));

      callback(selectOptions);
    });
  };

  const onClickInstitutionSignIn = (): void => {
    if (!institution) {
      return;
    }

    if (institution.url) {
      window.location.href = institution.url;
    } else if (institution.userGuide) {
      setIsOpen(true);
    }
  };

  return (
    <Container className={className}>
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          <LeftColumn>
            <IndividualForm onSubmit={onSubmit} aria-label="Individual user form">
              <Label htmlFor="username">
                <StyledIcon svgComponent={theme.images.user} />
                Username
              </Label>
              <StyledInput
                id="username"
                type="text"
                name="email"
                placeholder="Username"
                value={username}
                onChange={e => onUsernameChange(e.target.value.trim())}
                dataCy="email"
                error={!!errorMessage.username || !!errorMessage.form}
                autoComplete="email"
                inputRef={usernameInput}
                aria-describedby="sign-in-username-error sign-in-form-error"
                aria-invalid={!!errorMessage.username || !!errorMessage.form}
              />
              <FormMessage
                id="sign-in-username-error"
                type="error"
                message={errorMessage.username}
              />
              <LabelPassword htmlFor="password">
                <StyledIcon svgComponent={theme.images.password} />
                Password
              </LabelPassword>
              <StyledInput
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => onPasswordChange(e.target.value.trim())}
                dataCy="password"
                error={!!errorMessage.password || !!errorMessage.form}
                autoComplete="current-password"
                inputRef={passwordInput}
                aria-describedby="sign-in-password-error sign-in-form-error"
                aria-invalid={!!errorMessage.password || !!errorMessage.form}
              />
              <FormMessage
                id="sign-in-password-error"
                type="error"
                message={errorMessage.password}
              />
              <ReCaptchaNoticeText color="main" />
              <FormErrorMessage id="sign-in-form-error" type="error" message={errorMessage.form} />
              <FormButton type="submit" size="big" weight="500" disabled={isFormLoading}>
                Sign In
              </FormButton>
            </IndividualForm>
          </LeftColumn>
          <RightColumn>
            <InstitutionForm aria-label="Institutional user form">
              <Label htmlFor="institution-select">
                <StyledIcon svgComponent={theme.images.institution} />
                Sign in via your library
              </Label>
              <StyledSelect
                classNamePrefix="institution-select"
                placeholder="Start typing your institution name"
                showIcon
                openMenuOnClick={false}
                isClearable
                inputId="institution-select"
                loadOptions={loadInstitutions}
                onChange={onInstitutionChange}
              />
              <FormButton type="button" size="big" weight="500" onClick={onClickInstitutionSignIn}>
                Sign In
              </FormButton>
            </InstitutionForm>
            <Links aria-label="Access links">
              <LinkWrapper>
                <StyledIcon svgComponent={theme.images.reading} />
                <ExternalLink
                  to="https://www.gideononline.com/quoteform/"
                  openInNewTab
                  color="main"
                  size="big"
                >
                  Need GIDEON for your library?
                </ExternalLink>
              </LinkWrapper>
            </Links>
          </RightColumn>
        </>
      )}
      <AccountModal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Action required">
        {institution?.userGuide}. {institution?.email}
      </AccountModal>
    </Container>
  );
};
