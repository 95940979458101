import { getAllAvailableEditions, getEBook, getEBooks } from 'apiServices/eBooks/eBooks';
import { useContext, useEffect } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading } from 'store/types';
import { EBook, EBookURL } from 'types/eBooks';
import { CustomError, ErrorCodes } from 'types/errorTypes';
import { SelectOption } from 'types/select';

import { Action, EBooksDispatchContext, EBooksStateContext, State } from './provider';

export const useState = (): State => {
  const state = useContext(EBooksStateContext);
  if (state === undefined) {
    throw new CustomError('EBooksStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(EBooksDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('EBooksStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useEBooksList = (edition?: string | null): EBook[] | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'EBooks/List/LoadInitiated' });
    getEBooks('pdf', edition)
      .then(data => dispatch({ type: 'EBooks/List/Loaded', payload: data }))
      .catch(err =>
        dispatch({
          type: 'EBooks/List/LoadFailed',
          payload: newStoreError(err.message, err.code, err),
        })
      );
  }, [dispatch, edition]);

  assertIsNotStoreError(state.list);

  return state.list || 'Loading';
};

export const useEBook = (eBook: EBook | 'Loading' | null, edition?: string | null): EBookURL | 'Loading' | null => {
  const state = useState();
  const dispatch = useDispatch();

  const getCode = (eBook: EBook): string | null => {
    if (eBook.category === 'disease') {
      return eBook.diseaseCode.toString();
    } else if (eBook.category === 'country') {
      return eBook.countryCode;
    } else {
      return null;
    }
  };

  const code = !isLoading(eBook) && eBook ? getCode(eBook) : null;
  const id = !isLoading(eBook) && eBook ? eBook.title : null;

  const url = id ? state.urls[id] : null;

  useEffect(() => {
    if (!url && eBook && !isLoading(eBook) && id) {
      dispatch({ type: 'EBooks/Urls/LoadInitiated', payload: { key: id } });
      getEBook('pdf', eBook.category, code, edition)
        .then(data => {
          dispatch({ type: 'EBooks/Urls/Loaded', payload: { key: id, value: data } });
        })
        .catch(err =>
          dispatch({
            type: 'EBooks/Urls/LoadFailed',
            payload: { key: id, value: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [code, dispatch, eBook, id, url]);

  assertIsNotStoreError(url);

  if (!eBook) {
    return null;
  } else if (eBook && !isLoading(eBook) && url) {
    return url;
  } else {
    return 'Loading';
  }
};

export const useAvailableEditions = (): SelectOption[] | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.availableEditions) {
      return;
    }

    dispatch({ type: 'EBooks/Editions/LoadInitiated' });
    getAllAvailableEditions()
      .then(editions => dispatch({ type: 'EBooks/Editions/Loaded', payload: editions }))
      .catch(err =>
        dispatch({
          type: 'EBooks/Editions/LoadFailed',
          payload: newStoreError(err.message, err.code, err),
        })
      );
  }, [dispatch, state.availableEditions]);

  assertIsNotStoreError(state.availableEditions);

  return !state.availableEditions || isLoading(state.availableEditions)
    ? 'Loading'
    : state.availableEditions;
};
