import { ExploreFocusSection } from 'Molecules/ExploreFocusSectionMobile';
import { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 33px 0 20px 0;
`;

export const EbooksSelectorMobile: FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <Categories>
      <ExploreFocusSection
        caption="Countries"
        to="countries"
        Icon={theme.images.updates.content.countries}
      />
      <ExploreFocusSection
        caption="Diseases"
        to="diseases"
        Icon={theme.images.updates.content.diseases}
      />
      <ExploreFocusSection
        caption="GIDEON Guides"
        to="other"
        Icon={theme.images.updates.content.ebooks}
      />
    </Categories>
  );
};
