import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { EBook, EBookCategory, EBookURL } from 'types/eBooks';
import { SelectOption } from 'types/select';

import { EBookCategories, EBookDTO, EBookURLDTO, PurchasedEditionsDTO } from './eBooks.dto';

const eBookUrl = 'https://cloudfront.gideononline.com';

export const getEBooks = async (format: 'pdf', year?: string | null): Promise<EBook[]> => {
  const eBooks = await axios.get<DataDTO<EBookDTO[]>>(
    `/api/ebooks-portal/${format}${year ? `?year=${year}` : ''}`
  );

  return eBooks.data.data.map(ebook => ({
    title: ebook.book_title,
    category: ebook.book_category,
    coverImageURL: ebook.cover_image_url,
    countryCode: ebook.country_code,
    diseaseCode: ebook.disease_code,
  }));
};

const categories: Record<EBookCategory, EBookCategories> = {
  disease: 'diseases',
  country: 'countries',
  bacteria: 'bacteria',
  drug: 'drugs',
  outbreak: 'outbreaks',
  survey: 'surveys',
  travel: 'travel',
  vaccine: 'vaccines',
  yeasts: 'yeasts',
};

export const getEBook = async (
  format: 'pdf',
  category: EBookCategory,
  code?: string | null,
  year?: string | null,
): Promise<EBookURL | null> => {
  const eBook = code
    ? await axios.get<DataDTO<EBookURLDTO | null>>(
        `/api/ebooks-portal/${format}/${categories[category]}/${code}${year ? `?year=${year}` : ''}`
      )
    : await axios.get<DataDTO<EBookURLDTO[] | null>>(
        `/api/ebooks-portal/${format}/${categories[category]}${year ? `?year=${year}` : ''}`
      );

  const responseData = eBook.data.data;
  const data = Array.isArray(responseData) ? responseData[0] : responseData;

  return data
    ? {
        bookTitle: data.book_title,
        eBookURL: data.ebook_url.replace(
          eBookUrl,
          process.env.REACT_APP_EBOOK_CLOUDFRONT_URL || eBookUrl
        ),
      }
    : null;
};

export const getAllAvailableEditions = async (): Promise<SelectOption[]> => {
  const editions = await axios.get<PurchasedEditionsDTO>('/api/ebooks-portal/purchased-editions');

  if (editions.data.editions.length === 0) {
    const currentYear = new Date().getFullYear().toString();

    return [{ label: currentYear, value: currentYear }];
  }

  return editions.data.editions
    .sort((a, b) => b - a)
    .map(d => ({
      value: d.toString(),
      label: d.toString(),
    }));
};
