import { Location } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

export interface DeepLinkRedirectState {
  location: Location;
}

export const AuthWrapper: FC = ({ children }) => {
  const { isAuthenticated, isLoading } = useAccountController();
  const location = useLocation();

  const redirectState: DeepLinkRedirectState = {
    location: location,
  };

  if (isLoading) {
    return <Loader />;
  } else if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to={{ pathname: '/login' }} state={redirectState} />;
  }
};
