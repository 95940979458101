import { H1 } from 'Atoms/text/H';
import { P } from 'Atoms/text/P';
import { FC } from 'react';
import styled from 'styled-components/macro';

const TitleContainer = styled.div`
  margin-top: 50px;
`;

const Title = styled(H1)`
  margin: 0 0 10px 0;
`;

interface Props {
  className?: string;
  title: string;
  currentEdition?: string;
}

export const EbooksTitle: FC<Props> = ({ className, title, currentEdition }) => (
  <TitleContainer className={className}>
    <Title color="lightLink" weight="600" size="big" font="Inter">
      {title}
    </Title>
    {currentEdition && <P textAlign="center">{currentEdition} edition</P>}
  </TitleContainer>
);
