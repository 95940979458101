import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { ChangeEvent, FC, FocusEvent, RefObject, useContext, useState } from 'react';
import styled, { css, DefaultTheme, ThemeContext } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type SizeType = 'big' | 'normal';
const sizes: FontSizeCollection<SizeType> = {
  normal: {
    desktop: '18px',
    mobile: '16px',
  },
  big: {
    desktop: '30px',
    mobile: '20px',
  },
};

interface ContainerStyleProps {
  color?: keyof DefaultTheme['colors']['text'];
  background?: keyof DefaultTheme['colors']['background'];
}

const Container = styled.div<ContainerStyleProps>`
  display: flex;
  align-items: center;

  width: 100%;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.text[props.color || 'input']};
  background: ${props => props.theme.colors.background[props.background || 'primary']};

  position: relative;
`;

interface StyleProps {
  fontSize?: SizeType;
  color?: keyof DefaultTheme['colors']['text'];
  placeholderColor?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700';
  error?: boolean;
  background?: keyof DefaultTheme['colors']['background'];
}

const StyledInput = styled.input<StyleProps>`
  padding: 10px;
  width: 100%;
  max-width: 400px;
  font-size: ${props => sizes[props.fontSize || 'normal'].desktop};
  font-weight: ${props => props.weight || '400'};
  color: ${props => props.theme.colors.text[props.color || 'input']};
  background: ${props => props.theme.colors.background[props.background || 'primary']};
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.fontSize || 'big'].mobile};
  }

  border: none;
  border-radius: 10px;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  &::placeholder {
    color: ${props => props.theme.colors.text[props.placeholderColor || 'input']};
  }

  ${props =>
    props.error &&
    css`
      border: 1px solid ${props => props.theme.colors.text.error};
    `};

  &::-ms-reveal {
    display: none;
  }
`;

interface RevealButtonStyleProps {
  background?: keyof DefaultTheme['colors']['background'];
}

const RevealButtonStyled = styled(InvisibleButton)<RevealButtonStyleProps>`
  cursor: pointer;
  margin: 0 5px;

  border-radius: 10px;

  position: absolute;
  right: 0;

  height: 100%;
  background: ${props => props.theme.colors.background[props.background || 'primary']};

  &:hover {
    background: ${props => props.theme.colors.background[props.background || 'primary']};
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  className?: string;
  type: 'text' | 'email' | 'password';
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  dataCy?: string;
  id?: string;
  autoComplete?: string;
  size?: SizeType;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  color?: keyof DefaultTheme['colors']['text'];
  background?: keyof DefaultTheme['colors']['background'];
  placeholderColor?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700';
  'aria-describedby'?: string;
  'aria-invalid'?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
}

export const SignInInput: FC<Props> = ({
  className,
  type,
  name,
  placeholder,
  value,
  onChange,
  dataCy,
  id,
  autoComplete,
  size,
  onBlur,
  error,
  color,
  background,
  inputRef,
  ...rest
}) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const theme = useContext(ThemeContext);

  const getType = (): 'text' | 'email' | 'password' => {
    if (type === 'password') {
      return isRevealed ? 'text' : 'password';
    }

    return type;
  };

  return (
    <Container className={className} color={color} background={background}>
      <StyledInput
        type={getType()}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        data-cy={dataCy}
        id={id}
        autoComplete={autoComplete}
        fontSize={size}
        onBlur={onBlur}
        error={error}
        color={color}
        background={background}
        ref={inputRef}
        {...rest}
      />
      {type === 'password' && (
        <RevealButtonStyled
          onClick={() => setIsRevealed(revealed => !revealed)}
          type="button"
          background={background}
        >
          <Icon
            svgComponent={isRevealed ? theme.images.passwordHide : theme.images.passwordShow}
            size="smallMedium"
          />
        </RevealButtonStyled>
      )}
    </Container>
  );
};
