import { getAccountSettings } from 'apiServices/account/accountSettings';
import { institutionalLogin, ipLogin } from 'apiServices/auth/auth';
import { isErrorResponse } from 'apiServices/auth/auth.dto';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { FormEvent, ReactNode, RefObject, useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { AuthTokenContext } from 'services/localStorage/AuthToken.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { isStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Resource } from 'store/types';
import { AccountSettings } from 'types/accountSettings';
import { CustomError, ErrorCodes, isAxiosError } from 'types/errorTypes';

import {
  AccountDispatchContext,
  AccountStateContext,
  Action,
  IPLoginStatus,
  State,
} from './provider';

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(AccountDispatchContext);
  if (dispatch === undefined) {
    throw new CustomError('AccountStore', ErrorCodes.StoreNotInitialized);
  }
  return dispatch;
};

export const useState = (): State => {
  const state = React.useContext(AccountStateContext);
  if (state === undefined) {
    throw new CustomError('AccountStore', ErrorCodes.StoreNotInitialized);
  }
  return state;
};

/** useAccountSettigs should only be used once inside the account provider */
export const useAccountSettingsFetcher = (): void => {
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const { ipLoginStatus } = useContextAssert(AccountStateContext);
  const dispatch = useContextAssert(AccountDispatchContext);

  // Get authenticated user
  useEffect(() => {
    let isValid = true;
    dispatch({
      type: 'Account/Load',
    });
    if (!token) {
      dispatch({
        type: 'Account/LoadFailed',
        payload: newStoreError('Token is not set', ErrorCodes.Unauthorized),
      });
    } else {
      getAccountSettings()
        .then(data => {
          if (isValid) {
            dispatch({ type: 'Account/SkipIPLogin' });
            dispatch({ type: 'Account/Loaded', payload: data });
          }
        })
        .catch((err: AxiosError) => {
          const isUnauthorized = err.response?.status === 401;

          if (isValid && isUnauthorized) {
            setToken(null);
            dispatch({
              type: 'Account/LoadFailed',
              payload: newStoreError(err.message, ErrorCodes.Unauthorized, err),
            });
          }
        });
    }
    return () => {
      isValid = false;
    };
  }, [dispatch, setToken, token]);

  // Try IP login
  useEffect(() => {
    let isValid = true;
    if (ipLoginStatus === 'initial' && token === null) {
      const login = async (): Promise<void> => {
        try {
          const token = await ipLogin();
          if (isValid) {
            setToken(token);
            dispatch({ type: 'Account/UpdateIPLoginStatus', payload: 'success' });
          }
        } catch (err) {
          dispatch({ type: 'Account/UpdateIPLoginStatus', payload: 'fail' });
        }
      };

      login();
    }

    return () => {
      isValid = false;
    };
  }, [dispatch, ipLoginStatus, setToken, token]);
};

export interface TokenPayload {
  user: {
    institutionId: string | null;
  };
}

export interface ErrorFields {
  username: string | null;
  password: string | null;
  form: ReactNode | null;
}

export interface Fields {
  isFormLoading: boolean;
  username: string;
  password: string;
  setErrorMessage: (value: ErrorFields) => void;
  setAccountExpired: (value: boolean) => void;
  setIsSigningIn: (value: boolean) => void;
  usernameInput: RefObject<HTMLInputElement>;
  passwordInput: RefObject<HTMLInputElement>;
}

export type SignInEvent = (e: FormEvent<HTMLFormElement>, fields: Fields) => Promise<void>;

export interface AccountController {
  ipLoginStatus: IPLoginStatus;
  isLoading: boolean;
  isAuthenticated: boolean;
  accountSettings: Resource<AccountSettings>;
  token: string | null;
  signIn: SignInEvent;
  setToken: (token: string) => void;
  signOut: () => void;
  tokenPayload: TokenPayload | null;
}

export const useAccountController = (): AccountController => {
  const state = useContextAssert(AccountStateContext);
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getCustomErrorMessage = useCallback((error: string): ReactNode => {
    if (error === 'Unverified Email.') {
      return 'You have not verified your email yet.';
    } else if (error === 'You are not human.') {
      return 'Suspicious behaviour detected. Please try again.';
    } else {
      return error;
    }
  }, []);

  const validate = (fields: Fields): ErrorFields => {
    const errors: ErrorFields = {
      username: null,
      password: null,
      form: null,
    };

    if (!fields.password) {
      errors.password = 'Password must be provided';
      fields.passwordInput.current?.focus();
    }

    if (!fields.username) {
      errors.username = 'Username must be provided';
      fields.usernameInput.current?.focus();
    }

    return errors;
  };

  const setFormError = (error: ReactNode, fields: Fields): void => {
    fields.setErrorMessage({ username: null, password: null, form: error });
    fields.usernameInput.current?.focus();
  };

  const result = useMemo(
    () => ({
      ipLoginStatus: state.ipLoginStatus,
      isLoading: isLoading(state.accountSettings) || !executeRecaptcha,
      isAuthenticated: !isLoading(state.accountSettings) && !isStoreError(state.accountSettings),
      accountSettings: state.accountSettings,
      token,
      signIn: (e: FormEvent<HTMLFormElement>, fields: Fields): Promise<void> =>
        new Promise(resolve => {
          e.preventDefault();
          if (fields.isFormLoading || !executeRecaptcha) {
            return;
          }

          const errors = validate(fields);
          fields.setErrorMessage(errors);
          if (Object.values(errors).some(error => error)) {
            return;
          }

          fields.setIsSigningIn(true);

          executeRecaptcha('submit')
            .then(recaptchaToken =>
              institutionalLogin(fields.username, fields.password, recaptchaToken)
            )
            .then(token => {
              fields.setErrorMessage({ form: null, username: null, password: null });

              setToken(token);
              resolve();
            })
            .catch(err => {
              if (err === 'Subscription Expired.') {
                fields.setAccountExpired(true);
              } else if (isAxiosError(err) && err.response && isErrorResponse(err.response.data)) {
                setFormError(err.response.data.error.message, fields);
              } else if (err) {
                setFormError(getCustomErrorMessage(err), fields);
              } else {
                setFormError('Sign in failed', fields);
              }

              fields.setIsSigningIn(false);
            });
        }),
      setToken: setToken,
      signOut: () => {
        setToken(null);
        navigate('/login');
      },
      tokenPayload: token ? jwt_decode<TokenPayload>(token) : null,
    }),
    [
      executeRecaptcha,
      getCustomErrorMessage,
      navigate,
      setToken,
      state.accountSettings,
      state.ipLoginStatus,
      token,
    ]
  );

  return result;
};
