import Axios, { AxiosInstance } from 'axios.cached';
import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { AuthTokenContext } from 'services/localStorage/AuthToken.provider';
import { CustomError, ErrorCodes } from 'types/errorTypes';

import { useContextAssert } from './useContextAssert.hook';

interface ProviderProps {
  children: ReactNode;
}

type ContextValue = AxiosInstance | null;

const AxiosContext = createContext<ContextValue>(null);

export const AxiosProvider: FC<ProviderProps> = ({ children }) => {
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const [axios] = useState(() => {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          401 === error.response?.status &&
          !error.config.url.startsWith('/api/ebooks-portal/auth')
        ) {
          setToken(null);
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    const instance = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          401 === error.response?.status &&
          !error.config.url.startsWith('/api/ebooks-portal/auth')
        ) {
          setToken(null);
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    return instance;
  });

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export const useAxios = (): AxiosInstance => {
  const axios = useContext(AxiosContext);
  if (!axios) {
    throw new CustomError(`Axios is used outside of it's scope`, ErrorCodes.AxiosNotInitialized);
  }

  return axios;
};
