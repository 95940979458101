import { ReactComponent as ReadSvg } from 'assets/read.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { EBook as EBookType } from 'types/eBooks';

const HighlightContainer = styled.div`
  &[data-highlight='true'] {
    border: solid 1px ${props => props.theme.colors.text.inactive};
    border-radius: 4px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: 20px;
  width: 210px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 260px;
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  margin: 0 15px 0 0;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  margin: 10px 0;

  display: flex;
  justify-content: center;

  text-align: center;

  height: 55px;
`;

interface Props {
  data: EBookType;
  onClick: () => void;
  isHighlighted: boolean;
}

export const EBook: FC<Props> = ({ data, onClick, isHighlighted }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <HighlightContainer id={data.title} data-highlight={isHighlighted}>
      <Container>
        <ImageContainer>
          {isLoading && <Loader />}
          <CoverImage
            src={data.coverImageURL}
            onLoad={() => setIsLoading(false)}
            alt={data.title}
            title={data.title}
          />
        </ImageContainer>
        <TitleContainer>{data.title}</TitleContainer>
        <FilledButton onClick={onClick} aria-label={`Open digital reader for ${data.title}`}>
          <StyledIcon svgComponent={ReadSvg} size="small" />
          Read
        </FilledButton>
      </Container>
    </HighlightContainer>
  );
};
