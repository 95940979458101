import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { Institution } from 'types/institution';

import { InstitutionalLoginRequest, InstitutionalLoginResponse, InstitutionDTO } from './auth.dto';

export const institutionalLogin = async (
  username: string,
  password: string,
  recaptchaToken: string
): Promise<string> => {
  const request: InstitutionalLoginRequest = {
    username,
    password,
    recaptcha_token: recaptchaToken,
  };
  const res = await axios.post<InstitutionalLoginResponse>(
    '/api/ebooks-portal/auth/institution/login',
    request
  );

  return res.data.token;
};

export const ipLogin = async (): Promise<string> => {
  const res = await axios.post<InstitutionalLoginResponse>(
    '/api/ebooks-portal/auth/institution/login/ip'
  );

  return res.data.token;
};

export const getInstitutionList = async (search?: string): Promise<Institution[]> => {
  const response = await axios.get<DataDTO<InstitutionDTO[]>>(
    `/api/ebooks-portal/auth/institution/list?search=${search}&return_url=${process.env.REACT_APP_EBOOK_APP_URL}`
  );

  return response.data.data.map(i => ({
    name: i.institution_name,
    url: i.institution_url,
    userGuide: i.user_guide,
    contactEmail: i.contact_email,
    isSubscriber: i.is_subscriber !== 'N',
  }));
};
