import { ReactComponent as countries } from 'assets/EBook/Countries.svg';
import { ReactComponent as diseases } from 'assets/EBook/Diseases.svg';
import { ReactComponent as ebooks } from 'assets/EBook/Ebooks.svg';
import { ReactComponent as ClockSvg } from 'assets/Expiration/Clock.svg';
import FullLogoPng from 'assets/full-logo.png';
import { ReactComponent as InstitutionSvg } from 'assets/Login/Institution.svg';
import { ReactComponent as PasswordSvg } from 'assets/Login/Password.svg';
import { ReactComponent as ReadingSvg } from 'assets/Login/Reading.svg';
import { ReactComponent as UserSvg } from 'assets/Login/User.svg';
import LogoPng from 'assets/logo.png';
import { ReactComponent as eBookSVG } from 'assets/UI/eBookLight.svg';
import { ReactComponent as Help } from 'assets/UI/HelpLight.svg';
import { ReactComponent as PasswordHide } from 'assets/UI/PasswordHideLight.svg';
import { ReactComponent as PasswordShow } from 'assets/UI/PasswordShowLight.svg';
import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components/macro';

import { commonTheme } from './commonTheme';

export const lightTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: {
      primary: '#FFFFFF',
      navbar: '#FFFFFF',
      secondaryNavbar: '#04384A',
      secondaryNavbarActiveLink: rgba('#FFFFFF', 0.1),
      footer: rgba('#FFFFFF', 0.9),
      searchBar: '#f9f9f9',
      modalCardHeader: '#F8F9FA',
      modalCardBody: '#FFFFFF',
      recordTitle: '#FFFFFF',
      lightPopup: '#009FD5',
      darkPopup: '#005B7A',
      differentialDiagnosisPreview: rgba('#000000', 0.01),
    },
    button: {
      default: {
        backgroundColor: '#FFFFFF',
        borderColor: '#4F4F4F',
        textColor: '#4F4F4F',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        borderColor: '#055B7A',
        textColor: '#055B7A',
      },
      active: {
        backgroundColor: '#FFFFFF',
        borderColor: '#009FD5',
        textColor: '#009FD5',
      },
      disabled: {
        backgroundColor: 'transparent',
        borderColor: rgba(73, 80, 87, 0.6),
        textColor: rgba(73, 80, 87, 0.6),
      },
      focus: {
        backgroundColor: '#FFFFFF',
        borderColor: '#4F4F4F',
        textColor: '#4F4F4F',
        outline: '#007BFF',
      },
    },
    filledButton: {
      default: {
        default: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#055B7A',
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.6)',
        },
        hover: {
          backgroundColor: '#14607A',
          borderColor: '#055B7A',
          textColor: '#FFFFFF',
          boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        active: {
          backgroundColor: '#0ABFFC',
          borderColor: '#0ABFFC',
          textColor: '#000000',
        },
        focus: {
          backgroundColor: '#FFFFFF',
          borderColor: '#4F4F4F',
          textColor: '#055B7A',
          outline: '#007BFF',
        },
      },
    },
    select: {
      default: {
        background: '#FFFFFF',
        border: '#4F4F4F',
        text: '#4F4F4F',
        placeholder: '#4F4F4F',
        menuBackground: '#FFFFFF',
        multiOption: {
          text: '#FFFFFF',
          background: '#0077A0',
          closeButtonBackground: rgba(127, 207, 234, 0.5),
          closeButton: '#000000',
        },
      },
      active: {
        border: '#0077A0',
        text: '#004F6B',
      },
      hover: {
        option: rgba(127, 207, 234, 0.2),
      },
      focus: {
        border: '#007BFF',
      },
    },
    scrollbar: {
      track: '#DDDDDD',
      thumb: '#055B7A',
    },
    tab: {
      default: '#4F4F4F',
      hover: '#7FCFEA',
      active: '#055B7A',
      border: '#AAAAAA',
      borderActive: '#009FD5',
      focus: '#007BFF',
    },
    text: {
      main: '#000000',
      mainBold: '#000000',
      secondary: '#FFFFFF',
      inactive: '#4F4F4F',
      navbar: '#495057',
      navbarActive: '#055B7A',
      secondaryNavbar: '#FFFFFF',
      secondaryNavbarActive: '#FFFFFF',
      darkLink: '#055B7A',
      lightLink: '#0077A0',
      input: '#595959',
      selectInput: '#004F6B',
      errorTitle: '#595959',
      modalCardTitle: '#495057',
      exploreLandingTitle: '#000000',
      disabledNavLink: rgba(73, 80, 87, 0.6),
      error: '#C70E0E',
    },
    scrollToTopButton: {
      background: '#FFFFFF',
      border: '#009FD5',
    },
    settings: {
      sectionBorder: rgba('#80CFEA', 0.2),
      sectionSeparator: '#7FCFEA',
      inputBorder: '#009FD5',
      inputText: '#055B7A',
    },
    loader: '#009FD5',
    focus: '#007BFF',
    accent: {
      7: '#7FCFEA',
    },
    switch: {
      on: '#00BCD4',
      off: '#4f4f4f',
    },
    expirationButtons: {
      order: '#055B7A',
      quote: '#0077A0',
      talk: '#009FD5',
      text: '#FFFFFF',
    },
    trial: {
      socialProof: {
        border: '#F0F0F0',
        background: '#FFFFFF',
      },
      success: '#089E20',
    },
    accountMenu: {
      default: {
        backgroundColor: '#FFFFFF',
        borderColor: '#AAAAAA',
        textColor: '#495057',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        borderColor: '#0077A0',
        textColor: '#0077A0',
      },
    },
    modal: {
      base: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
      afterOpen: {
        overlayBackground: rgba(0, 0, 0, 0.4),
      },
      beforeClose: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
    },
    reportButton: {
      default: {
        backgroundColor: '#FFFFFF',
        textColor: '#055B7A',
        borderColor: '#4F4F4F',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        textColor: '#055B7A',
        borderColor: '#055B7A',
      },
      icon: '#C70E0E',
    },
  },
  images: {
    logo: FullLogoPng,
    mobileLogo: LogoPng,
    eBooks: eBookSVG,
    updates: {
      content: {
        countries: countries,
        diseases: diseases,
        ebooks: ebooks,
      },
    },
    clock: ClockSvg,
    passwordHide: PasswordHide,
    passwordShow: PasswordShow,
    user: UserSvg,
    password: PasswordSvg,
    institution: InstitutionSvg,
    reading: ReadingSvg,
    help: Help,
  },
};
