import { DataDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { AccountSettings } from 'types/accountSettings';

import { mapAccountSettingsDTO } from './accountSettings.map';
import { AccountSettingsDTO } from './DTO';

export const getAccountSettings = async (): Promise<AccountSettings> => {
  const settings = await axios.get<DataDTO<AccountSettingsDTO>>(
    '/api/ebooks-portal/account/settings'
  );
  const data = settings.data.data;

  return mapAccountSettingsDTO(data);
};
