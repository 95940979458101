import { ReactComponent as AlertIcon } from 'assets/UI/AlertIcon.svg';
import { Button } from 'Atoms/buttons/Button';
import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { Icon } from 'Atoms/Icon';
import { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

const ButtonStyle = styled(Button)`
  max-width: 200px;

  display: flex;
  align-items: center;
  padding: 8px 16px;

  background: ${props => props.theme.colors.reportButton.default.backgroundColor};
  color: ${props => props.theme.colors.reportButton.default.textColor};
  border-color: ${props => props.theme.colors.reportButton.default.borderColor};
  ${Icon} {
    width: 30px;
    height: 26px;
    fill: ${props => props.theme.colors.reportButton.icon};
  }

  &:hover {
    background: ${props => props.theme.colors.reportButton.hover.backgroundColor};
    color: ${props => props.theme.colors.reportButton.hover.textColor};
    border-color: ${props => props.theme.colors.reportButton.hover.borderColor};
    ${Icon} {
      fill: ${props => props.theme.colors.reportButton.icon};
    }
  }
`;

export const ReportButton: FC<Props> = ({ className, children, onClick }) => (
  <ButtonStyle className={className} onClick={onClick}>
    <Icon svgComponent={AlertIcon} />
    <ButtonLabel>{children}</ButtonLabel>
  </ButtonStyle>
);
