import { GlobalStyle } from 'globalStyle';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { AxiosProvider } from 'services/axios.service';
import { AuthTokenProvider } from 'services/localStorage/AuthToken.provider';
import { ThemeProvider } from 'services/theme';
import { AccountStoreProvider } from 'store/accountStore/provider';
import { EBooksStoreProvider } from 'store/eBookStore/provider';

import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <AuthTokenProvider>
      <AxiosProvider>
        <AccountStoreProvider>
          <EBooksStoreProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              useRecaptchaNet
            >
              <ThemeProvider>
                <GlobalStyle />
                <App />
              </ThemeProvider>
            </GoogleReCaptchaProvider>
          </EBooksStoreProvider>
        </AccountStoreProvider>
      </AxiosProvider>
    </AuthTokenProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
