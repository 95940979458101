import { MainLayout } from 'layouts/MainLayout';
import { EbooksSelectorMobile } from 'Molecules/EbooksSelectorMobile';
import { EbooksTitle } from 'Molecules/EbooksTitle';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EBookSection } from 'types/eBooks';

const Container = styled.div`
  padding: 10px;
`;

interface Props {
  sections: EBookSection[];
  currentEdition: string;
}

export const EbooksMobile: FC<Props> = ({ sections, currentEdition }) => (
  <MainLayout>
    <Routes>
      <Route
        path="/"
        element={
          <>
            <EbooksTitle title="GIDEON eBooks" />
            <EbooksSelectorMobile />
          </>
        }
      />
      {sections.map(section => (
        <Route
          path={section.slug}
          key={section.slug}
          element={
            <>
              <EbooksTitle title={section.name} currentEdition={currentEdition} />
              <Container>
                <section.Component />
              </Container>
            </>
          }
        />
      ))}
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </MainLayout>
);
