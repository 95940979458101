import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { Span } from 'Atoms/text/Span';
import { nanoid } from 'nanoid';
import { EventHandler, forwardRef, KeyboardEventHandler, MouseEvent, useMemo } from 'react';
import styled from 'styled-components/macro';

const AccountMenuOptionStyled = styled(InvisibleButton)`
  display: flex;
  padding: 10px 16px 10px 14px;
  cursor: pointer;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Icon} {
    height: 21px;
    width: 21px;
  }
`;

const NameContainer = styled(Span)`
  margin-left: 12px;
`;

const HiddenDescription = styled(Span)`
  display: none;
`;

interface Props {
  className?: string;
  icon: SvgComponent;
  label: string;
  onClick: EventHandler<MouseEvent<HTMLButtonElement>>;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  description?: string;
}

export const AccountMenuOption = forwardRef<HTMLButtonElement, Props>(
  ({ className, icon, label, onClick, onKeyDown, description }, ref) => {
    const id = useMemo(() => (description ? nanoid() : undefined), [description]);

    return (
      <AccountMenuOptionStyled
        className={className}
        onClick={onClick}
        ref={ref}
        onKeyDown={onKeyDown}
        aria-describedby={id}
      >
        <IconContainer>
          <Icon svgComponent={icon} fill="inactive" />
        </IconContainer>
        <NameContainer color="inactive" weight="500" size="smallStatic">
          {label}
        </NameContainer>
        {description && <HiddenDescription id={id}>{description}</HiddenDescription>}
      </AccountMenuOptionStyled>
    );
  }
);
