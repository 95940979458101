import { Link } from 'Atoms/links/Link';
import { H1, H2 } from 'Atoms/text/H';
import { MainLayout } from 'layouts/MainLayout';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin: 50px 20%;
`;

const Section = styled.div`
  border: 1px solid ${props => props.theme.colors.tab.border};
  border-radius: 10px;
`;

const Title = styled(H1)`
  margin: 40px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 0 0;
  }
`;

const SectionTitle = styled(H2)`
  text-align: left;

  margin: 10px 0;
`;

const ListItem = styled.li`
  margin: 5px 0;
`;

export const Sitemap: FC = () => (
  <MainLayout>
    <Helmet>
      <title>Site Map - GIDEON eBooks</title>
    </Helmet>
    <Title weight="500" font="Quicksand">
      GIDEON eBooks Site Map
    </Title>
    <Container>
      <SectionTitle size="regular">GIDEON eBooks</SectionTitle>
      <Section>
        <ul>
          <ListItem>
            <Link to="/">eBooks</Link>
            <ul>
              <ListItem>
                <Link to="/countries">Country eBooks</Link>
              </ListItem>
              <ListItem>
                <Link to="/diseases">Diseases eBooks</Link>
              </ListItem>
              <ListItem>
                <Link to="/other">GIDEON Guides</Link>
              </ListItem>
            </ul>
          </ListItem>
        </ul>
      </Section>
    </Container>
  </MainLayout>
);
