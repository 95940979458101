import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useState as useAccountState } from 'store/accountStore/hooks';
import { isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { ThemeProvider as StyledComponetThemeProvider } from 'styled-components/macro';

import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';

const noop = (): void => {
  /* noop */
};

const mockedProviderState = {
  isDarkMode: false,
  setIsDarkMode: noop,
};

const mockedMediaQueryList = { matches: false, addEventListener: noop, removeEventListener: noop };

const themeLocalStorageKey = 'color-theme';
const savedPreference = window ? window.localStorage.getItem(themeLocalStorageKey) : null;
const mediaQuery = window
  ? window.matchMedia('(prefers-color-scheme: dark)')
  : mockedMediaQueryList;

const getInitialTheme = (): boolean => {
  if (savedPreference) {
    return savedPreference === 'true';
  }

  return mediaQuery.matches;
};

const getThemeFromApi = (theme: string): boolean =>
  theme === 'light' ? false : theme === 'dark' ? true : getInitialTheme();

export const ThemeModeContext = createContext<{
  isDarkMode: boolean;
  setIsDarkMode: (value: boolean) => void;
}>(mockedProviderState);

export const ThemeProvider: FC = ({ children }) => {
  const { accountSettings } = useAccountState();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(getInitialTheme());

  useEffect(() => {
    if (
      !isLoading(accountSettings) &&
      !isStoreError(accountSettings) &&
      accountSettings.accountType === 'individual' &&
      accountSettings.defaultColorTheme
    ) {
      setIsDarkMode(getThemeFromApi(accountSettings.defaultColorTheme));
    }
  }, [accountSettings]);

  const memoState = useMemo(
    () => ({
      isDarkMode,
      setIsDarkMode: (value: boolean) => {
        window && window.localStorage.setItem(themeLocalStorageKey, value.toString());
        setIsDarkMode(value);
      },
    }),
    [isDarkMode, setIsDarkMode]
  );

  const onMediaChange = (event: Event): void => {
    setIsDarkMode((event.target as MediaQueryList).matches);
  };

  useEffect(() => {
    if (!savedPreference) {
      mediaQuery.addEventListener('change', onMediaChange);
      return () => {
        mediaQuery.removeEventListener('change', onMediaChange);
      };
    }
  });

  return (
    <ThemeModeContext.Provider value={memoState}>
      <StyledComponetThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        {children}
      </StyledComponetThemeProvider>
    </ThemeModeContext.Provider>
  );
};
