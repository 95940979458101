import { CopyrightText } from 'Molecules/CopyrightText';
import { FooterLinks } from 'Molecules/FooterLinks';
import { LicenseAgreementModal } from 'Molecules/modal/LicenseAgreementModal';
import { TermsModal } from 'Molecules/modal/TermsModal';
import { FC, useState } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';

const FooterStyled = styled.footer`
  box-sizing: border-box;
  width: 100%;
  padding: 15px 35px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.background.footer};
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    padding: 15px 10px;
  }
`;

const FooterLinksStyled = styled(FooterLinks)`
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 0 0 auto;
  }
`;

const FooterText = styled(CopyrightText)`
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 20px;
    white-space: nowrap;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 10px;
  }
`;

export const Footer: FC = () => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);

  const { tokenPayload } = useAccountController();

  const isInsitution = !!tokenPayload?.user.institutionId;

  return (
    <FooterStyled>
      <FooterText textAlign="center" />
      <FooterLinksStyled
        onTermsClick={() => setIsTermsModalOpen(true)}
        onLicenseClick={() => setIsLicenseModalOpen(true)}
      />
      <TermsModal isOpen={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)} />
      <LicenseAgreementModal
        isOpen={isLicenseModalOpen}
        onClose={() => setIsLicenseModalOpen(false)}
        isInstitution={isInsitution}
      />
    </FooterStyled>
  );
};
