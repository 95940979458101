import { AuthWrapper } from 'Atoms/AuthWrapper';
import { AppLayout } from 'layouts/AppLayout';
import { NotFound } from 'pages/404';
import { Home } from 'pages/Home';
import { PDF } from 'pages/PDF';
import { SignIn } from 'pages/SignIn';
import { TokenLogin } from 'pages/SignIn/tokenLogin';
import { Sitemap } from 'pages/Sitemap';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

const App = (): ReactElement => (
  <Routes>
    <Route
      path="/pdf"
      element={
        <AuthWrapper>
          <PDF />
        </AuthWrapper>
      }
    />
    <Route path="/login/token" element={<TokenLogin />} />
    <Route path="/login" element={<SignIn />} />
    <Route
      path="/*"
      element={
        <AuthWrapper>
          <AppLayout />
        </AuthWrapper>
      }
    >
      <Route path="sitemap" element={<Sitemap />} />
      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<Home />} />
    </Route>
  </Routes>
);

export default App;
