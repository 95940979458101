import { ReactComponent as ArrowDownIcon } from 'assets/UI/ArrowDown.svg';
import { ReactComponent as MenuMobileIcon } from 'assets/UI/MenuMobile.svg';
import { AccountMenuContainer } from 'Atoms/accountMenu/AccountMenuContainer';
import { AccountMenuOption as AccountMenuOptionComponent } from 'Atoms/accountMenu/AccountMenuOption';
import { AccountMenuOptions } from 'Atoms/accountMenu/AccountMenuOptions';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import { SearchSelectMenuSeparator } from 'Atoms/select/SearchSelectMenuSeparator';
import { AccountMenuInfo } from 'Molecules/AccountMenuInfo';
import { FC, KeyboardEvent, useRef, useState } from 'react';
import { useClickOutside } from 'services/useClickOutside.hook';
import styled from 'styled-components/macro';
import { AccountMenuOption } from 'types/accountMenuOption';

const ContainerStyled = styled.div`
  z-index: 850;

  margin: 0 0 0 auto;
`;

const Button = styled(InvisibleButton)`
  display: none;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
`;

const MobileIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`;

const SettingsButtons = styled(InvisibleButton)`
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-family: ${props => props.theme.fontFamily.Inter};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: ${props => props.theme.colors.text.navbar};
  text-decoration: none;
  transition: color 0.3s;
  &:hover {
    color: ${props => props.theme.colors.text.navbarActive};
  }
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const ArrowDownIconStyled = styled(Icon)`
  width: 8px;
  height: 5px;
  margin-left: 10px;
`;

interface Props {
  className?: string;
  options: AccountMenuOption[];
}

export const AccountMenu: FC<Props> = ({ className, options }) => {
  const [isOpen, setOpen] = useState(false);

  const onChangeWrapper = (value: AccountMenuOption): void => {
    value.onClick();
    setOpen(false);
  };

  const optionReferences = useRef<(HTMLButtonElement | null)[]>([]);
  const innerButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef.current, () => setOpen(false));

  const onMoreKeyDown = (e: KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const reference = optionReferences.current[0];

      if (!reference) {
        return;
      }

      reference.focus();
    }
  };

  const onAccountMenuOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>, index: number): void => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextRef = optionReferences.current[index + 1];

      if (!nextRef) {
        return;
      }

      nextRef.focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevRef = optionReferences.current[index - 1];

      if (!prevRef) {
        return;
      }

      prevRef.focus();
    }
  };

  const onContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Escape') {
      setOpen(false);
      innerButtonRef?.current?.focus();
    }
  };

  const onClickMenuOption = (value: AccountMenuOption): void => {
    onChangeWrapper(value);
    innerButtonRef?.current?.focus();
  };

  return (
    <ContainerStyled ref={containerRef} className={className} onKeyDown={onContainerKeyDown}>
      <Button
        onClick={() => setOpen(o => !o)}
        onKeyDown={onMoreKeyDown}
        aria-label="Settings button"
        ref={innerButtonRef}
        aria-expanded={isOpen}
      >
        <MobileIcon svgComponent={MenuMobileIcon} />
      </Button>
      <SettingsButtons
        onClick={() => setOpen(o => !o)}
        onKeyDown={onMoreKeyDown}
        data-cy="settings"
        ref={innerButtonRef}
        aria-expanded={isOpen}
      >
        More <ArrowDownIconStyled svgComponent={ArrowDownIcon} />
      </SettingsButtons>
      <AccountMenuContainer isOpen={isOpen}>
        <AccountMenuInfo isOpen={isOpen} />
        {isOpen && (
          <AccountMenuOptions>
            <SearchSelectMenuSeparator />
            {options.map((option, i) => (
              <AccountMenuOptionComponent
                key={option.value}
                onClick={() => onClickMenuOption(option)}
                icon={option.Icon}
                label={option.label}
                ref={ref => (optionReferences.current[i] = ref)}
                onKeyDown={e => onAccountMenuOptionKeyDown(e, i)}
                description={option.description}
              />
            ))}
          </AccountMenuOptions>
        )}
      </AccountMenuContainer>
    </ContainerStyled>
  );
};
