import { FC } from 'react';
import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { AccountSettings } from 'types/accountSettings';

import { useAccountSettingsFetcher } from './hooks';
import { reducer } from './reducer';

export type Action =
  | GenericAction<'Account/Load'>
  | ActionWithPayload<'Account/Loaded', AccountSettings>
  | ActionWithPayload<'Account/LoadFailed', StoreError>
  | ActionWithPayload<'Account/UpdateIPLoginStatus', IPLoginStatus>
  | GenericAction<'Account/SkipIPLogin'>;

export interface Account {
  data: {
    id: string;
    name: string;
  }[];
}

export type IPLoginStatus = 'initial' | 'success' | 'fail' | 'skipped';

export type State = {
  accountSettings: Resource<AccountSettings>;
  ipLoginStatus: IPLoginStatus;
};

const initialState: State = {
  accountSettings: 'Loading',
  ipLoginStatus: 'initial',
};

const { dispatchContext, stateContext, provider: Provider } = storeFactory(reducer, initialState);

export const AccountDispatchContext = dispatchContext;
export const AccountStateContext = stateContext;

const AccountDataFetcher: FC = () => {
  useAccountSettingsFetcher();
  return null;
};

export const AccountStoreProvider: FC = ({ children }) => (
  <Provider>
    <AccountDataFetcher />
    {children}
  </Provider>
);

export const AccountStoreProviderMock: FC = ({ children }) => <Provider>{children}</Provider>;
