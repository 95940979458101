import { MainLayout } from 'layouts/MainLayout';
import { EbooksTitle } from 'Molecules/EbooksTitle';
import { Tabs } from 'Molecules/Tabs';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EBookSection } from 'types/eBooks';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  margin-top: 50px;
  padding: 0 10%;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 0 5%;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 5px;
  }
`;

const TabsStyled = styled(Tabs)`
  justify-content: unset;
`;

interface Props {
  sections: EBookSection[];
  currentEdition: string;
}

export const EbooksDesktop: FC<Props> = ({ sections, currentEdition }) => (
  <MainLayout>
    <EbooksTitle title="GIDEON eBooks" currentEdition={currentEdition} />
    <Content>
      <TabsStyled sections={sections} />
      <Routes>
        <Route path="/" element={<Navigate to="countries" />} />
        {sections.map(section => (
          <Route path={section.slug} key={section.slug} element={<section.Component />} />
        ))}
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Content>
  </MainLayout>
);
