import { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ModalCardHeader } from './ModalCardHeader';

const ModalCardBody = styled.div`
  font: ${props => props.theme.fonts.normalText};
  padding: 10px 14px 20px;
  color: ${props => props.theme.colors.text.main};
  background-color: ${props => props.theme.colors.background.modalCardBody};
`;

const ModalCardWrapper = styled.div`
  position: relative;
`;

interface ModalCardProps {
  className?: string;
  title: string;
  subTitle?: ReactNode;
  children: ReactNode;
}

export const ModalCard: FC<ModalCardProps> = ({ className, title, subTitle, children }) => (
  <ModalCardWrapper className={className}>
    <ModalCardHeader title={title} subTitle={subTitle} />
    <ModalCardBody>{children}</ModalCardBody>
  </ModalCardWrapper>
);
