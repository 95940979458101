import { createGlobalStyle } from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.3s linear;
    background: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.text.main};
    ${ScrollbarMixin};
  }

  html, body, #root {
    height: 100%;
  }
  
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const GlobalStylePDF = createGlobalStyle`
  html {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;

    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.3s linear;
    background: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.text.main};
  }

  html, body, #root {
    height: 100%;
  }
`;

export const DisableAnimationsGlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    transition: none !important;
  }
`;
