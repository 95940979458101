import { H1, P } from 'Atoms/text';
import { NotFoundImage } from 'Molecules/NotFoundImage';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

const NotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 100px 10px 32px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) and (min-width: ${props =>
      props.theme.breakpoints.m}) {
    padding: 100px 20px 32px;
  }
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding: 100px 48px 32px;
  }
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto 40%;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const ExplanationSection = styled.div`
  display: flex;
  margin: 20px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: center;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) {
    justify-content: flex-end;
  }
`;

const NotFoundImageStyled = styled(NotFoundImage)`
  width: 100%;
  justify-self: end;

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    grid-row: 1 / 3;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-row: 1 / 2;
  }
`;

const TitleStyled = styled(H1)`
  text-align: unset;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font: ${props => props.theme.fonts.HeaderBold};
    margin: 0 0 20px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.s}) and (max-width: ${props =>
      props.theme.breakpoints.m}) {
    margin: 0 0 30px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 0 40px 0;
  }
`;

export const NotFound: FC = () => (
  <NotFoundPage>
    <Helmet>
      <title>Not found - GIDEON eBooks</title>
    </Helmet>
    <GridSection>
      <NotFoundImageStyled />
      <TextContainer>
        <TitleStyled weight="600" font="Inter" size="veryBig" color="errorTitle" lineHeight="unset">
          Hmm... I don’t recognize this bug.
        </TitleStyled>
      </TextContainer>
    </GridSection>
    <ExplanationSection>
      <P textAlign="center" size="big" color="inactive">
        For the techies among us, this is error 404
      </P>
    </ExplanationSection>
  </NotFoundPage>
);
