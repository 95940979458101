import { FC, useLayoutEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountController } from 'store/accountStore/hooks';

export const TokenLogin: FC = () => {
  const { setToken } = useAccountController();

  const location = useLocation();

  const hash = location.hash.slice(1);

  const params = new URLSearchParams(location.search);

  const token = params.get('token') || hash;
  const target = params.get('target') || '/';

  useLayoutEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [setToken, token]);

  return <Navigate to={target} />;
};
