import { ReactComponent as QuoteSvg } from 'assets/Expiration/Quote.svg';
import { ReactComponent as ShoppingCartSvg } from 'assets/Expiration/ShoppingCart.svg';
import { ReactComponent as TalkSvg } from 'assets/Expiration/Talk.svg';
import { ButtonLabel } from 'Atoms/buttons/ButtonLabel';
import { FilledButton, StyledSpan } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link, Location } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H1, P } from 'Atoms/text';
import moment from 'moment';
import { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useState as useAccountState } from 'store/accountStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled, { ThemeContext } from 'styled-components/macro';

const LogoLink = styled(Link)`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);

  display: block;
  &:hover {
    opacity: 0.9;
  }
`;

const Logo = styled.img`
  display: block;
  width: auto;
  height: 25px;
`;

const Container = styled.div`
  padding: 10px 10px 50px 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(H1)`
  margin-top: 50px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 20px;
  }
`;

const ClockIcon = styled(Icon)`
  width: 80px;
  height: 80px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0 0 20px;
`;

const Box = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.button.active.borderColor};
  display: flex;
  flex-direction: column;
  padding: 35px 25px;
  margin: 20px 20px 0 0;
`;

const BoxTitle = styled(P)`
  flex-grow: 1;
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const StyledButton = styled(FilledButton)`
  max-width: 160px;
  max-height: 50px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid;
  }
  ${StyledSpan} {
    color: ${props => props.theme.colors.expirationButtons.text};
  }
  ${StyledIcon} {
    fill: ${props => props.theme.colors.expirationButtons.text};
  }
`;

const OrderButton = styled(StyledButton)`
  background: ${props => props.theme.colors.expirationButtons.order};
`;

const QuoteButton = styled(StyledButton)`
  background: ${props => props.theme.colors.expirationButtons.quote};
`;

const TalkButton = styled(StyledButton)`
  background: ${props => props.theme.colors.expirationButtons.talk};
`;

const StyledLink = styled(Link)`
  margin-top: 50px;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid ${props => props.theme.colors.text.inactive};
`;

interface AccessExpirationContentProps {
  className?: string;
  expiresIn?: number;
  to?: string | Location;
}

export const AccessExpirationContent: FC<AccessExpirationContentProps> = ({
  className,
  expiresIn,
  to,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Content className={className}>
      <ClockIcon svgComponent={theme.images.clock} />
      <Title color="lightLink" weight="600" size="big" font="Inter">
        {expiresIn !== undefined
          ? `Your access expires in ${expiresIn} ${expiresIn === 1 ? 'hour' : 'hours'}`
          : 'Your access has expired'}
      </Title>
      <P color="input" weight="700" size="medium" textAlign="center">
        take action now to keep using GIDEON
      </P>
      <ButtonsContainer>
        <Box>
          <BoxTitle textAlign="center" size="medium">
            Need GIDEON for personal use?
          </BoxTitle>
          <OrderButton onClick={() => window.open('https://www.gideononline.com/order/')}>
            <StyledIcon svgComponent={ShoppingCartSvg} />
            <ButtonLabel>Order here</ButtonLabel>
          </OrderButton>
        </Box>
        <Box>
          <BoxTitle textAlign="center" size="medium">
            Need GIDEON for your library?
          </BoxTitle>
          <QuoteButton onClick={() => window.open('https://www.gideononline.com/quoteform/')}>
            <StyledIcon svgComponent={QuoteSvg} />
            <ButtonLabel>Get a quote</ButtonLabel>
          </QuoteButton>
        </Box>
        <Box>
          <BoxTitle textAlign="center" size="medium">
            Got questions?
          </BoxTitle>
          <TalkButton onClick={() => window.open('https://www.gideononline.com/contact/')}>
            <StyledIcon svgComponent={TalkSvg} />
            <ButtonLabel>Let&#39;s talk</ButtonLabel>
          </TalkButton>
        </Box>
      </ButtonsContainer>

      {to && expiresIn !== undefined && (
        <StyledLink to={to} color="inactive" size="big">
          I will look at this later, proceed to GIDEON
        </StyledLink>
      )}
    </Content>
  );
};

const AccessExpirationContentStyled = styled(AccessExpirationContent)`
  padding-top: 150px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 70px;
  }
`;

interface Props {
  expiresIn?: number;
  to?: string | Location;
}

const AccessExpirationBase: FC<Props> = ({ expiresIn, to }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container>
      <Helmet>
        <title>Expiration - GIDEON</title>
      </Helmet>
      <LogoLink to="/">
        <Logo src={theme.images.logo} alt="Gideon logo" />
      </LogoLink>
      <AccessExpirationContentStyled expiresIn={expiresIn} to={to} />
    </Container>
  );
};

export const AccessExpired: FC = () => {
  return <AccessExpirationBase />;
};

export const AccessExpiration: FC = () => {
  const { accountSettings } = useAccountState();
  const location = useLocation();

  const to = location.state && location.state.location ? location.state.location : '/';

  assertIsNotStoreError(accountSettings);

  if (isLoading(accountSettings)) {
    return <Loader />;
  }

  const expiresIn = moment(accountSettings.subscriptionExpireDate).diff(moment(), 'hours');

  if (expiresIn <= 24) {
    return <AccessExpirationBase expiresIn={expiresIn} to={'/'} />;
  }

  return <Navigate to={to} />;
};
