import { Span } from 'Atoms/text';
import { FC } from 'react';
import styled from 'styled-components/macro';

import { InvisibleButton } from './buttons/InvisibleButton';

const Wrapper = styled(InvisibleButton)`
  display: flex;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 45px;
  min-width: 45px;
  height: 27px;
`;

interface StyleProps {
  checked?: boolean;
}

const Slider = styled.span<StyleProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    props.checked ? props.theme.colors.switch.on : props.theme.colors.switch.off};
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: ${props => (props.checked ? '-2px' : '7px')};
    bottom: 6px;
    background-color: ${props => props.theme.colors.background.primary};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${props => (props.checked ? 'translateX(26px)' : 'translateX(0)')};
  }
`;

const Label = styled(Span)`
  margin-left: 15px;
`;

interface Props {
  className?: string;
  checked?: boolean;
  onClick?: () => void;
  label: string;
}

export const Switch: FC<Props> = ({ className, checked, onClick, label }) => (
  <Wrapper className={className} onClick={onClick}>
    <SwitchWrapper>
      <Slider checked={checked} />
    </SwitchWrapper>
    <Label weight="500">{label}</Label>
  </Wrapper>
);
