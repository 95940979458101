import { ReactComponent as countries } from 'assets/EBook/CountriesDark.svg';
import { ReactComponent as diseases } from 'assets/EBook/DiseasesDark.svg';
import { ReactComponent as ebooks } from 'assets/EBook/EbooksDark.svg';
import { ReactComponent as ClockSvg } from 'assets/Expiration/ClockDark.svg';
import FullDarkLogoPng from 'assets/full-logo-dark.png';
import { ReactComponent as InstitutionSvg } from 'assets/Login/InstitutionDark.svg';
import { ReactComponent as PasswordSvg } from 'assets/Login/PasswordDark.svg';
import { ReactComponent as ReadingSvg } from 'assets/Login/ReadingDark.svg';
import { ReactComponent as UserSvg } from 'assets/Login/UserDark.svg';
import LogoPng from 'assets/logo.png';
import { ReactComponent as eBookSVG } from 'assets/UI/eBookDark.svg';
import { ReactComponent as Help } from 'assets/UI/HelpDark.svg';
import { ReactComponent as PasswordHide } from 'assets/UI/PasswordHideDark.svg';
import { ReactComponent as PasswordShow } from 'assets/UI/PasswordShowDark.svg';
import { mix, rgba } from 'polished';
import { DefaultTheme } from 'styled-components/macro';

import { commonTheme } from './commonTheme';

const mainBackground = '#202124';

export const darkTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: {
      primary: mainBackground,
      navbar: rgba('#FFFFFF', 0.09),
      secondaryNavbar: '#04384A',
      secondaryNavbarActiveLink: rgba('#FFFFFF', 0.1),
      footer: rgba('#FFFFFF', 0.12),
      searchBar: '#2A2A2A',
      modalCardHeader: '#343538',
      modalCardBody: '#292929',
      recordTitle: '#022A38',
      lightPopup: '#009FD5',
      darkPopup: '#005B7A',
      differentialDiagnosisPreview: rgba('#FFFFFF', 0.1),
    },
    button: {
      default: {
        backgroundColor: 'transparent',
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
      disabled: {
        backgroundColor: 'transparent',
        borderColor: rgba(170, 170, 170, 0.8),
        textColor: rgba(170, 170, 170, 0.8),
      },
      focus: {
        backgroundColor: 'transparent',
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
        outline: '#F4C74F',
      },
    },
    filledButton: {
      default: {
        default: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          boxShadow: '0px 1px 4px 0px rgba(2,42,56,0.4)',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25)',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
        },
        focus: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          outline: '#F4C74F',
        },
      },
    },
    select: {
      default: {
        background: rgba('#FFFFFF', 0.1),
        border: rgba('#FFFFFF', 0.8),
        text: rgba('#FFFFFF', 0.8),
        placeholder: rgba('#FFFFFF', 0.8),
        menuBackground: rgba('#000000', 0.9),
        multiOption: {
          text: '#FFFFFF',
          background: '#0077A0',
          closeButtonBackground: rgba(127, 207, 234, 0.5),
          closeButton: '#000000',
        },
      },
      active: {
        border: '#7FCFEA',
        text: rgba('#FFFFFF', 0.87),
      },
      hover: {
        option: rgba(127, 207, 234, 0.2),
      },
      focus: {
        border: '#F4C74F',
      },
    },
    scrollbar: {
      track: '#999',
      thumb: rgba('#7FCFEA', 0.8),
    },
    tab: {
      default: rgba('#FFFFFF', 0.8),
      hover: '#0077A0',
      active: '#7FCFEA',
      border: '#AAAAAA',
      borderActive: '#00ACEA',
      focus: '#F4C74F',
    },
    text: {
      main: rgba('#FFFFFF', 0.8),
      mainBold: rgba('#FFFFFF', 0.87),
      secondary: rgba('#FFFFFF', 0.8),
      inactive: rgba('#FFFFFF', 0.8),
      navbar: rgba('#FFFFFF', 0.8),
      navbarActive: '#7FCFEA',
      secondaryNavbar: rgba('#FFFFFF', 0.87),
      secondaryNavbarActive: '#FFFFFF',
      darkLink: rgba('#7FCFEA', 0.8),
      lightLink: '#7FCFEA',
      input: rgba('#FFFFFF', 0.8),
      selectInput: rgba('#FFFFFF', 0.87),
      errorTitle: rgba('#FFFFFF', 0.87),
      modalCardTitle: rgba('#FFFFFF', 0.87),
      exploreLandingTitle: rgba('#7FCFEA', 0.87),
      disabledNavLink: rgba(170, 170, 170, 0.8),
      error: rgba('#F75555', 0.8),
    },
    scrollToTopButton: {
      background: '#7FCFEA',
      border: '#000000',
    },
    settings: {
      sectionBorder: rgba('#7FCFEA', 0.8),
      sectionSeparator: '#7FCFEA',
      inputBorder: '#AAAAAA',
      inputText: rgba('#FFFFFF', 0.8),
    },
    loader: rgba('#009FD5', 0.8),
    focus: '#F4C74F',
    accent: {
      7: '#7FCFEA',
    },
    switch: {
      on: '#00BCD4',
      off: '#AAAAAA',
    },
    expirationButtons: {
      order: '#004F6B',
      quote: '#055B7A',
      talk: '#0077A0',
      text: rgba('#FFFFFF', 0.8),
    },
    trial: {
      socialProof: {
        border: '#404043',
        background: '#404043',
      },
      success: '#51CF66',
    },
    accountMenu: {
      default: {
        backgroundColor: mix(0.16, '#FFFFFF', mainBackground),
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
      },
      hover: {
        backgroundColor: mix(0.16, '#FFFFFF', mainBackground),
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
    },
    modal: {
      base: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
      afterOpen: {
        overlayBackground: rgba(0, 0, 0, 0.4),
      },
      beforeClose: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
    },
    reportButton: {
      default: {
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: '#7FCFEA',
        borderColor: rgba('#FFFFFF', 0.6),
      },
      hover: {
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: '#7FCFEA',
        borderColor: '#7FCFEA',
      },
      icon: '#F4C74F',
    },
  },
  images: {
    logo: FullDarkLogoPng,
    mobileLogo: LogoPng,
    eBooks: eBookSVG,
    updates: {
      content: {
        countries: countries,
        diseases: diseases,
        ebooks: ebooks,
      },
    },
    clock: ClockSvg,
    passwordHide: PasswordHide,
    passwordShow: PasswordShow,
    user: UserSvg,
    password: PasswordSvg,
    institution: InstitutionSvg,
    reading: ReadingSvg,
    help: Help,
  },
};
