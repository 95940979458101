import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const LogoLink = styled(Link)`
  display: block;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }
`;

const FullLogo = styled.img`
  display: block;
  width: auto;
  height: 25px;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 35px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

const Logo = styled.img`
  display: none;
  width: 37px;
  height: 40px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

const Button = styled.button`
  background: none;
  border: unset;
  padding: 0;
  cursor: pointer;
  color: ${props => props.theme.colors.text.lightLink};
  font: ${props => props.theme.fonts.smallTextBold};
  margin-top: 5px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

interface Props {
  to: string;
  onClick?: () => void;
  label?: string;
}

export const NavbarLogo: FC<Props> = ({ onClick, label, to }) => {
  const theme = useContext(ThemeContext);

  return (
    <LogoWrapper role="banner">
      <LogoLink to={to}>
        <FullLogo src={theme.images.logo} alt="Gideon logo" />
        <Logo src={theme.images.mobileLogo} alt="Gideon logo" />
      </LogoLink>
      {onClick && label && <Button onClick={onClick}>{label}</Button>}
    </LogoWrapper>
  );
};
