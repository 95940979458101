import { AxiosError } from 'axios.cached';

export type CustomErrorType = 'Error' | 'FatalError' | 'NetworkError';

export class CustomError extends Error {
  type: CustomErrorType;
  code?: ErrorCodes;
  detail?: string;

  constructor(
    message: string,
    code?: ErrorCodes,
    type?: CustomErrorType,
    detail?: string,
    name?: string
  ) {
    super(message);

    this.code = code;
    this.type = type || 'Error';
    this.detail = detail;
    this.name = name || '';
  }
}

export type Errors = Error | CustomError | AxiosError;

//TODO: Expand error codes
export enum ErrorCodes {
  Unauthorized = 401,
  NotFound = 404,
  AxiosNotInitialized = 1000,
  MissingMandatoryProps = 1001,
  StoreNotInitialized = 1002,
  LocalStorageProvider = 1003,
  DynamicContentBadAttribute = 1004,
  DynamicContentParseError = 1005,
  UnhandledStep = 1006,
}

export const isAxiosError = <T>(value: T | AxiosError): value is AxiosError => {
  return (value as AxiosError).isAxiosError;
};
