import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { Dictionary } from 'types/common';
import { EBook, EBookURL } from 'types/eBooks';
import { SelectOption } from 'types/select';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'EBooks/List/LoadInitiated'>
  | ActionWithPayload<'EBooks/List/Loaded', EBook[]>
  | ActionWithPayload<'EBooks/List/LoadFailed', StoreError>
  | ActionWithPayload<'EBooks/Urls/LoadInitiated', { key: string }>
  | ActionWithPayload<'EBooks/Urls/Loaded', { key: string; value: EBookURL | null }>
  | ActionWithPayload<'EBooks/Urls/LoadFailed', { key: string; value: StoreError }>
  | GenericAction<'EBooks/Editions/LoadInitiated'>
  | ActionWithPayload<'EBooks/Editions/Loaded', SelectOption[]>
  | ActionWithPayload<'EBooks/Editions/LoadFailed', StoreError>;

export type State = {
  list: Resource<EBook[]> | null;
  urls: Dictionary<Resource<EBookURL> | null>;
  availableEditions: Resource<SelectOption[]> | null;
};

const initialState: State = {
  list: null,
  urls: {},
  availableEditions: null,
};

export const {
  dispatchContext: EBooksDispatchContext,
  stateContext: EBooksStateContext,
  provider: EBooksStoreProvider,
} = storeFactory(reducer, initialState);
