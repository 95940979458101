import { omit } from 'lodash';
import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

export type Size = 'small' | 'smallStatic' | 'normal' | 'medium' | 'big' | 'veryBig';
const sizes: FontSizeCollection<Size> = {
  small: {
    desktop: '15px',
    mobile: '12px',
  },
  smallStatic: {
    desktop: '15px',
    mobile: '15px',
  },
  normal: {
    desktop: '16px',
    mobile: '14px',
  },
  medium: {
    desktop: '18px',
    mobile: '16px',
  },
  big: {
    desktop: '20px',
    mobile: '16px',
  },
  veryBig: {
    desktop: '25px',
    mobile: '16px',
  },
};

interface Props extends ComponentPropsWithoutRef<'span'> {
  className?: string;
  children: ReactNode;
  id?: string;
}

const SpanBase: FC<Props> = ({ className, children, id, ...rest }) => (
  <span
    className={className}
    id={id}
    {...omit(rest, ['size', 'color', 'weight', 'fontStyle', 'font'])}
  >
    {children}
  </span>
);

interface StyleProps {
  size?: Size;
  color?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700' | '900';
  fontStyle?: 'italic' | 'normal';
  font?: keyof DefaultTheme['fontFamily'];
}

export const Span = styled(SpanBase)<StyleProps>`
  color: ${props => props.theme.colors.text[props.color || 'main']};
  font-family: ${props => props.theme.fontFamily[props.font || 'Inter']};
  font-weight: ${props => props.weight || '400'};
  font-size: ${props => sizes[props.size || 'normal'].desktop};
  font-style: ${props => props.fontStyle || 'normal'};
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'normal'].mobile};
  }
`;
