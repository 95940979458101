import { SearchSelectMenuSeparator } from 'Atoms/select/SearchSelectMenuSeparator';
import { ReactElement } from 'react';
import { components, MenuListProps } from 'react-select';
import { SelectOption } from 'types/select';

const { MenuList } = components;

export const SearchSelectMenuList = <T extends SelectOption, isMulti extends boolean>({
  children,
  ...props
}: MenuListProps<T, isMulti>): ReactElement => (
  <MenuList {...props}>
    {props.selectProps.menuPlacement === 'bottom' && (
      <SearchSelectMenuSeparator
        className={`${props.selectProps.classNamePrefix}__menu-separator`}
      />
    )}
    {children}
  </MenuList>
);
