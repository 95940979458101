import { Loader } from 'Atoms/Loader';
import { EBookContainer } from 'Organisms/EBookContainer';
import { EbooksDesktop } from 'Organisms/EbooksDesktop';
import { EbooksMobile } from 'Organisms/EbooksMobile';
import { FC, useCallback, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { useMobile } from 'services/useMobile';
import { useAvailableEditions, useEBooksList } from 'store/eBookStore/hooks';
import { isLoading } from 'store/types';
import { ThemeContext } from 'styled-components/macro';
import { EBookSection } from 'types/eBooks';
import { SelectOption } from 'types/select';

interface Props {
  editions: SelectOption[];
}

const EbooksBase: FC<Props> = ({ editions }) => {
  const theme = useContext(ThemeContext);
  const isMobile = useMobile('m');
  const { year } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const eBooks = useEBooksList(year);

  const setCurrentEdition = useCallback(
    (newYear: string | null) => {
      if (!newYear) {
        return;
      }

      const sections = location.pathname.split('/');
      const lastSection = sections[sections.length - 1];

      navigate(`/${newYear}/${lastSection}`);
    },
    [location.pathname, navigate]
  );

  const countriesEBooks = useMemo(() => {
    if (isLoading(eBooks)) {
      return [];
    }

    const eBook = eBooks.find(eb => eb.title === 'Infectious Diseases of the World');

    if (!eBook) {
      return [];
    }

    return [
      eBook,
      ...eBooks
        .filter(
          eb =>
            eb.title !== 'Infectious Diseases and Bioterrorism' &&
            eb.category === 'country' &&
            eb.title !== 'Infectious Diseases of the World'
        )
        .sort((a, b) => a.title.localeCompare(b.title)),
    ];
  }, [eBooks]);

  const countriesOptions = useMemo(
    () => countriesEBooks.map(eb => ({ value: eb.title, label: eb.title })),
    [countriesEBooks]
  );

  const diseasesEBooks = useMemo(() => {
    if (isLoading(eBooks)) {
      return [];
    }

    return eBooks
      .filter(eb => eb.category === 'disease')
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [eBooks]);

  const diseasesOptions = useMemo(
    () => diseasesEBooks.map(eb => ({ value: eb.title, label: eb.title })),
    [diseasesEBooks]
  );

  const otherEBooks = useMemo(() => {
    if (isLoading(eBooks)) {
      return [];
    }

    return eBooks
      .filter(
        eb =>
          eb.title === 'Infectious Diseases and Bioterrorism' ||
          (eb.category !== 'country' && eb.category !== 'disease')
      )
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [eBooks]);

  const otherOptions = useMemo(
    () => otherEBooks.map(eb => ({ value: eb.title, label: eb.title })),
    [otherEBooks]
  );

  if (!year || !editions.find(e => e.value === year)) {
    return <Navigate to="/404" />;
  }

  const sections: EBookSection[] = [
    {
      name: 'Countries',
      slug: 'countries',
      Component: () => (
        <>
          <Helmet>
            <title>Countries eBooks - GIDEON eBooks</title>
            <meta
              name="description"
              content="Read the current edition of the GIDEON ebook series, covering 361 infectious diseases in 235 countries and territories. Search by country of interest."
            />
          </Helmet>
          <EBookContainer
            eBooks={countriesEBooks}
            options={countriesOptions}
            editionOptions={editions}
            currentEdition={year}
            setCurrentEdition={setCurrentEdition}
          />
        </>
      ),
      icon: theme.images.updates.content.countries,
    },
    {
      name: 'Diseases',
      slug: 'diseases',
      Component: () => (
        <>
          <Helmet>
            <title>Diseases eBooks - GIDEON eBooks</title>
            <meta
              name="description"
              content="Read the current edition of the GIDEON ebook series, covering 361 infectious diseases in 235 countries and territories. Search by disease of interest."
            />
          </Helmet>
          <EBookContainer
            eBooks={diseasesEBooks}
            options={diseasesOptions}
            editionOptions={editions}
            currentEdition={year}
            setCurrentEdition={setCurrentEdition}
          />
        </>
      ),
      icon: theme.images.updates.content.diseases,
    },
    {
      name: 'GIDEON Guides',
      slug: 'other',
      Component: () => (
        <>
          <Helmet>
            <title>GIDEON Guides - GIDEON eBooks</title>
            <meta
              name="description"
              content="Read the GIDEON guides to 2,000+ medically important pathogens, cross-border infections, antimicrobial agents, surveys, outbreaks, and bioterrorism."
            />
          </Helmet>
          <EBookContainer
            eBooks={otherEBooks}
            options={otherOptions}
            editionOptions={editions}
            currentEdition={year}
            setCurrentEdition={setCurrentEdition}
          />
        </>
      ),
      icon: theme.images.updates.content.ebooks,
    },
  ];

  const Ebooks = isMobile ? EbooksMobile : EbooksDesktop;

  return (
    <>
      <Helmet>
        <title>Ebooks - GIDEON eBooks</title>
      </Helmet>
      <Ebooks sections={sections} currentEdition={year} />
    </>
  );
};

export const Home: FC = () => {
  const editions = useAvailableEditions();

  if (isLoading(editions)) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/${editions[0].value}`} />} />
      <Route path="/countries" element={<Navigate to={`/${editions[0].value}/countries`} />} />
      <Route path="/diseases" element={<Navigate to={`/${editions[0].value}/diseases`} />} />
      <Route path="/other" element={<Navigate to={`/${editions[0].value}/other`} />} />
      <Route path="/:year/*" element={<EbooksBase editions={editions} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
