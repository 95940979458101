import { SearchSelectControl } from 'Atoms/select/SearchSelectControl';
import { SearchSelectDropdownIndicator } from 'Atoms/select/SearchSelectDropdownIndicator';
import { SearchSelectMenuList } from 'Atoms/select/SearchSelectMenuList';
import { StyledSelectBase } from 'Atoms/select/SelectStyles';
import { FC } from 'react';
import ReactSelect, { ActionMeta, OnChangeValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styled from 'styled-components/macro';
import { SelectOption } from 'types/select';

interface Props {
  classNamePrefix: string;
  className?: string;
  showDropdown?: boolean;
  isSearchable?: boolean;
  showIcon?: boolean;
  name?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  value?: SelectOption | null;
  onChange?: (
    selected: OnChangeValue<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
  filterOption?: (option: FilterOptionOption<SelectOption>, inputValue: string) => boolean;
  onInputChange?: (value: string) => void;
  openMenuOnClick?: boolean;
  placeholder: string;
  options: SelectOption[];
  ariaLabel?: string;
  id?: string;
  inputId?: string;
  controlShouldRenderValue?: boolean;
  hideSelectedOptions?: boolean;
  defaultValue?: SelectOption;
}

const SearchSelectBase: FC<Props> = ({ classNamePrefix = 'search-select', ariaLabel, ...rest }) => (
  <ReactSelect
    isMulti={false}
    components={{
      Control: SearchSelectControl,
      MenuList: SearchSelectMenuList,
      DropdownIndicator: SearchSelectDropdownIndicator,
    }}
    classNamePrefix={classNamePrefix}
    aria-label={ariaLabel}
    {...rest}
  />
);

export const SearchSelect = styled(SearchSelectBase)`
  ${StyledSelectBase};
`;
