import { ErrorBoundary } from 'errorBoundary';
import { Footer } from 'Organisms/Footer';
import { Navbar } from 'Organisms/Navbar';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const AppLayout: FC = () => (
  <Container>
    <Navbar />
    <ErrorBoundary>
      <Main>
        <Outlet />
      </Main>
    </ErrorBoundary>
    <Footer />
  </Container>
);
