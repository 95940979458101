import { omit } from 'lodash';
import { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { FontSizeCollection } from 'types/fontSizeCollection';

type Size = 'small' | 'normal' | 'big' | 'veryBig';
const sizes: FontSizeCollection<Size> = {
  small: {
    desktop: '15px',
    mobile: '12px',
  },
  normal: {
    desktop: '16px',
    mobile: '14px',
  },
  big: {
    desktop: '20px',
    mobile: '16px',
  },
  veryBig: {
    desktop: '25px',
    mobile: '16px',
  },
};

interface Props {
  className?: string;
  children: ReactNode;
  to: string;
  size?: Size;
  color?: keyof DefaultTheme['colors']['text'];
  weight?: '400' | '500' | '600' | '700';
  noUnderline?: boolean;
  openInNewTab?: boolean;
  download?: boolean | string;
  title?: string;
  'aria-label'?: string;
}

const BasicExternalLink: FC<Props> = ({
  className,
  children,
  to,
  openInNewTab,
  download,
  title,
  ...rest
}) => {
  const props = openInNewTab
    ? {
        className,
        href: to,
        title,
        download,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : { className, href: to, title };

  return (
    <a {...props} {...omit(rest, 'noUnderline')}>
      {children}
    </a>
  );
};

export const ExternalLink = styled(BasicExternalLink)`
  color: ${props => props.theme.colors.text[props.color || 'darkLink']};
  font-family: ${props => props.theme.fontFamily.Inter};
  font-weight: ${props => props.weight || '400'};
  font-size: ${props => sizes[props.size || 'normal'].desktop};
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => sizes[props.size || 'normal'].mobile};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.focus};
  }

  ${props => (props.noUnderline ? 'text-decoration: none;' : '')}
`;
